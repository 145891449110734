<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="username"
      name="username"
      label="username"
      placeholder="用户名"
      :rules="[{required: true, message: '请填写用户名'}]"
    />
    <van-field
      v-model="password"
      type="password"
      name="password"
      label="password"
      placeholder="密码"
      :rules="[{required: true, message: '请填写密码'}]"
    />
    <!-- <van-field
      v-model="sms"
      center
      clearable
      label="短信验证码"
      placeholder="请输入短信验证码"
    >
      <template #button>
        <van-button size="small" type="primary">发送验证码</van-button>
      </template>
    </van-field> -->
    <div style="margin: 16px;">
      <van-button round block type="info" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>
<script>
import {mapMutations} from "vuex";
import {loginByPwd} from "@/utils/api"; // 导入我们的api接口
export default {
  data() {
    return {
      username: "",
      password: "",
      sms: "",
      userToken: "",
    };
  },
  methods: {
    ...mapMutations(["changeLogin"]),

    onSubmit(values) {
      let _that = this;
      loginByPwd({
        mobile: values.username,
        msgCode: values.sms,
        password: values.password,
      })
        .then((res) => {
          _that.userToken = res.data.token;

          // 将用户token保存到vuex中
          _that.changeLogin({Authorization: _that.userToken});
          _that.$router.push("/mall");
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
